import $ from "jquery"

var Voucher = (function () {

    var api_key = "7462d013-2161-4469-bac0-95fe3b405c3b";
    var basketId = null;
    var voucher_attr = {}; // ZusÃ¤tzlich Attribute fÃ¼r die Gutscheine im Warenkorb. voucher_attr[basketItemId] = { versand: 'Post', kuvert: 'rot' }


    function init() {

        $('.step-addtocart .btn').on('click', { identifier: $(this).data.identifier, test: 'XYZ' }, addToCart);
        if (!basketId) {
            basketId = localStorage.getItem('basketId');
        }
        if (!basketId) {
            createBasket();
        } else {
            showCart();
        }

        if (localStorage.getItem('voucher_attr')) {
            voucher_attr = JSON.parse(localStorage.getItem('voucher_attr'));
        }


        $('.cm-form-vouchers form').off('submit').on('submit', order);

        $('input[name="voucher[shipment]"]').on('click', function () {
            if ($('input[name="voucher[shipment]"]:checked').val() == "Post") {
                $('.step-envelope').show();
                $('.voucher-layout').addClass('post').removeClass('email neutral')
            } else {
                $('.step-envelope').hide();
                $('.voucher-layout').addClass('email').removeClass('post neutral')
            }
        });

        // set Values
        $('.voucher-values button').on('click', function () {
            var val = $(this).attr('data-val');
            $('input[name="voucher[value]"]').val(val);
            $('.voucher-layout__value').html(val + ' &euro;');
            return false;
        });
        $('input[name="voucher[value]"]').on('input', function () {
            var val = $(this).val();
            $('.voucher-layout__value').html(val + ' &euro;');
        });

        // set Text in Voucher-Layout
        $('textarea[name="voucher[text]"]').on('keyup', function () {
            var message = $(this).val();
            var words = message.split(' ');
            var lines = [''];
            var line = 0;
            var maxchars = 120;
            var chars = message.length;

            words.forEach(function (item) {
                if ((lines[line].length + item.toString().length) >= 45) {
                    line++;
                    lines[line] = '';
                }
                lines[line] = lines[line] + item + ' ';
            });
            message = '';
            lines.forEach(function (item) {
                item = '<tspan x="892" dy="52px">' + item + '</tspan>';
                message = message + item;
            });
            $('.voucher-layout__text').html(message);
            $('.voucher-text__charcount .chars').text(chars);
            $('.voucher-text__charcount .maxchars').text(maxchars);
            if (chars > maxchars) { return false };
        });

        // set Date in Voucher-Layout
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        today = dd + '.' + mm + '.' + yyyy;
        $('.voucher-layout__date').html(today)

        // Preselect Country
        let countryCode = navigator.language.slice(-2)
        $('select[name="address[country]"]').val(countryCode).attr('value', countryCode)
        $('select[name="deliveryaddress[country]"]').val(countryCode).attr('value', countryCode)

        // toggle Delivery-Address
        $('input[name="address[showDelivery]"]').on('click', function () {
            if ($(this).prop('checked')) {
                $('.voucher-address-delivery').removeClass('disabled');
            } else {
                $('.voucher-address-delivery').addClass('disabled');
            }
        });
        if ($('input[name="address[showDelivery]"]').prop('checked')) {
            $('.voucher-address-delivery').removeClass('disabled');
        }

        // select Voucher if only one is present
        if($('.vouchers-motives .vouchers-motive').length == 1) {
            $('.vouchers-motives .vouchers-motive input[name="voucher[motive]"]').prop('checked', true)
        }

        //$('.gutschein-layout-hint').on('click', addCoupon);
    }

    async function createBasket() {
        var url = "https://connect.protel.net/WBE/1/" + api_key + "/basket/new?format=json&isocode=de";
        await fetch(url, {
            method: 'GET'
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                if (data.success) {
                    basketId = data.data;
                    localStorage.setItem('basketId', basketId);
                    showCart();
                } else {
                    alert("Warenkorb konnte nicht angelegt werden.");
                }
            });

    }

    async function addToCart(e) {
        // BEGIN get Indentifier
        console.log('Add to Cart:', e.data.identifier, e.data.test)
        console.log('Event:', e)
        let currentTarget = e.currentTarget
        console.log('currentTarget:', currentTarget)
        // console.log('identifier:', currentTarget.data('identifier'))
        console.log('identifier:', currentTarget.attr('data-identifier'))
        // END get Indentifier

        var voucher_id = $('input[name="voucher[motive]"]:checked').val();
        var versandart = $('input[name="voucher[shipment]"]:checked').val();
        var kuvert = $('input[name="voucher[envelope]"]:checked').val();
        var amount = parseInt($('input[name="voucher[value]"]').val(), 10);
        var text = $('textarea[name="voucher[text]"]').val();

        if (!voucher_id) {
            alert('Bitte Gutschein auswählen.');
            return false;
        }
        if (!amount) {
            alert('Bitte Betrag auswählen.');
            return false;
        }

        var min = parseInt($('input[name="voucher[value]"]').attr('min'), 10);
        if (amount < min) {
            alert("Mindestbetrag: " + min + " EUR");
            return false;
        }

        if (amount > 5000) {
            alert("Maximalbetrag: 5000 EUR");
            return false;
        }

        if (!versandart) {
            alert("Bitte Versandart auswählen.");
            return false;
        }

        if (versandart == 'Post' && !kuvert) {
            alert("Bitte Kuvert auswählen.");
            return false;
        }

        var uuid = uuidv4();
        var basketItem = {
            amount: {
                currency: 'EUR',
                value: amount
            },
            emailReply: true,
            mpehotel: 10637,
            notes: uuid,
            recipient: {
                salutation: 'Herr',
                firstname: 'Phillip',
                lastname: 'Seidel',
                company: 'Designhotel Laurichhof',
                street: 'Hauptplatz 4',
                zip: '01796',
                city: 'Pirna',
                country: 'DE',
                phone: '03501 77090-77',
                // Diese E-Mail muss auch im Clpid Center unter Messenger -> Versandregeln -> Gutscheinverkauf - Bestellung abgeschlossen -> Filter eingetragen sein.
                email: 'gutschein@laurichhof.de'
            },
            voucherType: voucher_id
        };


        var url = "https://connect.protel.net/WBE/1/" + api_key + "/basket/vouchers?format=json&isocode=de&basketId=" + basketId;
        await fetch(url, {
            method: 'POST',
            body: JSON.stringify(basketItem),
            headers: { 'Content-Type': 'application/json' }
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);

                if (!data.success) {
                    return createBasket();
                }

                voucher_attr[data.data.id] = { versand: versandart, kuvert: kuvert, text: text };
                localStorage.setItem('voucher_attr', JSON.stringify(voucher_attr));
                showCart();
            });
						
						

        // Gutschein-Daten auf Server speichern
        basketItem.text = text;
        basketItem.versand = versandart;
        basketItem.kuvert = kuvert;
        basketItem.basketId = basketId;
        var url = "/gutschein-speichern.php";
        await fetch(url, {
          method: 'POST',
          body: JSON.stringify(basketItem),
          headers: { 'Content-Type': 'application/json' }
        })
        .then(res => res.json())
        .then(data => {
          //console.log(data);
        });

        $('.cm-form-vouchers form')[0].reset();
        $('.voucher-layout').removeClass('digital post').addClass('neutral');
        $('.voucher-layout__text').html('');
        $('.voucher-layout__value').html('');
    }

    /*
    async function addCoupon() {
        var code = "Laurichs10von10";
        
        var url = "https://connect.protel.net/WBE/1/" + api_key + "/basket/coupon?format=json&isocode=de&basketId=" + basketId;
        await fetch(url, {
            method: 'POST',
            body: JSON.stringify({ 'code': code }),
            headers: { 'Content-Type': 'application/json' }
        })
        .then(res => res.json())
        .then(data => {
            console.log(data);
            showCart();
            
        });
        
    }
    */

    async function showCart() {
        var url = "https://connect.protel.net/WBE/1/" + api_key + "/basket?format=json&isocode=de&basketId=" + basketId;
        await fetch(url, {
            method: 'GET'
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data);

                if (!data.success) {
                    // z.B. Shopping basket is expired
                    return createBasket();
                }


                $('.voucher-cart .total-price td.price').text(parseInt(data.data.price.valueNumber, 10) + ' EUR');

                $('.voucher-cart tbody').empty();
                if (!data.data.vouchers) {
                    return false;
                }
                data.data.vouchers.forEach(function (value, index) {
                    //console.log(value);

                    // Bild ermitteln
                    var imageUri;
                    if (voucher_attr[value.id].versand == 'Post') {
                        switch (voucher_attr[value.id].kuvert) {
                            case 'white':
                                imageUri = $('#motive-' + value.voucherType).data('white')
                                break
                            case 'pink':
                                imageUri = $('#motive-' + value.voucherType).data('pink')
                                break
                            case 'black':
                                imageUri = $('#motive-' + value.voucherType).data('black')
                                break
                        }
                    } else {
                        imageUri = $('#motive-' + value.voucherType).data('email')
                    }

                    var tr = $('<tr>').data('id', value.id);

                    var name = "Wertgutschein";
                    if (index > 0) {
                        name += " " + (index + 1);
                    }
                    var html = '<div><strong class="article-name">' + name + '</strong></div> <div class="article-notes">' + voucher_attr[value.id].text + '</div>';
                    if (voucher_attr[value.id]) {
                        html += '<div>Versandart: ' + voucher_attr[value.id].versand + '</div>';
                    }
                    /*
                    if (voucher_attr[value.id] && voucher_attr[value.id].versand == 'Post') {
                        html+="<div>Kuvert: " + voucher_attr[value.id].kuvert + '</div>';
                    }
                    */

                    var td = $('<td>').html(html);
                    if(imageUri) {
                        td.append('<img src="' + imageUri + '" class="article-image" />');
                    } else {
                        console.warn('Kein Vorschaubild vorhanden.')
                        // td.append('<div><i class="fa-solid fa-triangle-exclamation"></i> Kein Vorschaubild vorhanden.</div>')
                    }
                    tr.append(td);
                    td = $('<td class="price">').html(parseInt(value.amount.valueNumber, 10) + ' EUR');
                    tr.append(td);

                    td = $('<td><button type="button" class="btn-remove" title="Artikl aus Warenkorb entfernen"><i class="fa-solid fa-trash"></i></td>');
                    tr.append(td);

                    tr.find('button').on('click', removeFromCart);

                    $('.voucher-cart tbody').append(tr);
                });
            });

    }

    async function removeFromCart() {
        var id = $(this).closest('tr').data('id');

        var url = "https://connect.protel.net/wbe/1/" + api_key + "/basket/vouchers/" + id + "?format=json&isocode=de&basketId=" + basketId;
        await fetch(url, {
            method: 'DELETE'
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                delete (voucher_attr[id]);
                localStorage.setItem('voucher_attr', JSON.stringify(voucher_attr));
                showCart();
            });
    }


    function order() {
        var container = $('body');
				if ($('input[name="address[firstName]"]').parents('dialog').length) {
					// innerhalb eines Dialogs?
					container = $('dialog[open]');
				}

        var valid = true;
        if ($('input[name="address[showDelivery]"]', container).prop('checked')) {
            if (!$('input[name="deliveryaddress[firstName]"]', container).val() ||
                !$('input[name="deliveryaddress[lastName]"]', container).val() ||
                !$('input[name=li-"deliveryaddress[street]"]', container).val() ||
                !$('input[name="deliveryaddress[zip]"]', container).val() ||
                !$('input[name="deliveryaddress[city]"]', container).val()) {
                alert("Bitte abweichende Lieferadresse eingeben.");
                return false;
            }
        }


        order1();
        newsletter();

        return false;
    }

    /*
     * Bestell-Daten auf Server speichern
     */
    async function order1() {
			  var container = $('body');
				if ($('input[name="address[firstName]"]').parents('dialog').length) {
					// innerhalb eines Dialogs?
					container = $('dialog[open]');
				}
				
        var data = {
            type: "order",
            basketId: basketId,
            anrede: $('select[name="address[salutation]"]', container).val(),
            titel: $('input[name="address[title]"]', container).val(),
            vorname: $('input[name="address[firstName]"]', container).val(),
            nachname: $('input[name="address[lastName]"]', container).val(),
            firma: $('input[name="address[company]"]', container).val(),
            strasse: $('input[name="address[street]"]', container).val(),
            plz: $('input[name="address[zip]"]', container).val(),
            ort: $('input[name="address[city]"]', container).val(),
            land: $('select[name="address[country]"]', container).val(),
            tel: $('input[name="address[tel]"]', container).val(),
            email: $('input[name="address[email]"]', container).val(),
            lieferadresse: $('input[name="address[showDelivewry]"]', container).prop('checked') ? 1 : 0,
            liefer_anrede: $('select[name="deliveryaddress[salutation]"]', container).val(),
            liefer_titel: $('input[name="deliveryaddress[title]"]', container).val(),
            liefer_firma: $('input[name="deliveryaddress[company]"]', container).val(),
            liefer_vorname: $('input[name="deliveryaddress[firstName]"]', container).val(),
            liefer_nachname: $('input[name="deliveryaddress[lastName]"]', container).val(),
            liefer_strasse: $('input[name="deliveryaddress[street]"]', container).val(),
            liefer_plz: $('input[name="deliveryaddress[zip]"]', container).val(),
            liefer_ort: $('input[name="deliveryaddress[city]"]', container).val(),
            liefer_land: $('select[name="deliveryaddress[country]"]', container).val(),
            bemerkungen: $('textarea[name="voucher[message]"]', container).val(),
            broschuere: $('input[name="voucher[brochure]"]', container).prop('checked') ? 1 : 0
        };



        var url = "/gutschein-speichern.php";
        await fetch(url, {
             method: 'POST',
             body: JSON.stringify(data),
             headers: { 'Content-Type': 'application/json' },
        })
        .then(res => res.json())
        .then(data => {
          order2();
        });

    }

    /**
     * Success-URL setzen
     */
    async function order2() {
        var url = "https://connect.protel.net/WBE/1/" + api_key + "/basket/success?format=json&isocode=de&basketId=" + basketId;
        await fetch(url, {
            method: 'POST',
            body: JSON.stringify({ url: location.origin + '/saferpay-erfolgreich.html' }),
            headers: { 'Content-Type': 'application/json' },
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                order3();
            });

    }

    /*
     * Fail-URL setzen
     */
    async function order3() {
        var url = "https://connect.protel.net/WBE/1/" + api_key + "/basket/failed?format=json&isocode=de&basketId=" + basketId;
        await fetch(url, {
            method: 'POST',
            body: JSON.stringify({ url: location.origin + '/saferpay-fehlgeschlagen.html' }),
            headers: { 'Content-Type': 'application/json' },
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                order4();
            });
    }

    /*
     * Booker
     */
    async function order4() {
			  var container = $('body');
				if ($('input[name="address[firstName]"]').parents('dialog').length) {
					// innerhalb eines Dialogs?
					container = $('dialog[open]');
				}

			
        var booker = {
            salutation: $('select[name="address[salutation]"]', container).val(),
            firstname: $('input[name="address[firstName]"]', container).val(),
            lastname: $('input[name="address[lastName]"]', container).val(),
            company: $('input[name="address[company]"]', container).val(),
            street: $('input[name="address[street]"]', container).val(),
            zip: $('input[name="address[zip]"]', container).val(),
            city: $('input[name="address[city]"]', container).val(),
            phone: $('input[name="address[tel]"]', container).val(),
            email: 'info@laurichhof.de',
            allowMarketing: 0,
            allowStatistics: 0,
            anonymizerMode: 1
        };

        var url = "https://connect.protel.net/WBE/1/" + api_key + "/basket/booker?format=json&isocode=de&basketId=" + basketId;
        await fetch(url, {
            method: 'POST',
            body: JSON.stringify(booker),
            headers: { 'Content-Type': 'application/json' },
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                order5();
            });
    }

    /*
     * Status Payment
     */
    async function order5() {

        var url = "https://connect.protel.net/WBE/1/" + api_key + "/basket/payment?format=json&isocode=de&basketId=" + basketId;
        await fetch(url, {
            method: 'GET'
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                order6();
            });
    }

    /*
     * Checkout
     */
    async function order6() {
        var url = "https://connect.protel.net/WBE/1/" + api_key + "/basket/checkout?format=json&isocode=de&basketId=" + basketId;
        await fetch(url, {
            method: 'GET'
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);

                // Cookie setzen, damit saferpay-success.pl und saferpay-cancel.pl wissen, dass es um eine Gutschein-Bestellung geht.
                var date = new Date();
                date.setTime(date.getTime() + (1 * 60 * 60 * 1000));
                document.cookie = 'gutschein=' + basketId + '; path=/; expires=' + date.toUTCString();

                localStorage.removeItem('basketId');
                localStorage.removeItem('voucher_attr');

                location.href = data.data.action;

            });
    }

    /*
     * Newsletter-Anmeldung
     */
    async function newsletter() {
			  var container = $('body');
				if ($('input[name="address[firstName]"]').parents('dialog').length) {
					// innerhalb eines Dialogs?
					container = $('dialog[open]');
				}
			
        if (!$('input[name="voucher[newsletter]"]', container).prop('checked')) {
            return;
        }
        var url = "https://www.laurichhof.de/cgi-bin/newsletter.pl?anrede=" + encodeURIComponent($('select[name="address[salutation]"]', container).val());
        url += "&email=" + encodeURIComponent($('input[name="address[email]"]', container).val());
        url += "&vorname=" + encodeURIComponent($('input[name="address[firstName]"]', container).val());
        url += "&nachname=" + encodeURIComponent($('input[name="address[lastName]"]', container).val());

        await fetch(url, {
            method: 'GET',
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
            });
    }

    // https://stackoverflow.com/a/2117523
    function uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    // Gebe Ã¶ffentliches Schnittstellen-Objekt zurÃ¼ck
    return {
        init: init
        //filterSelected: filterSelected
    };

})();
Voucher.init();